<template>
<!--  <div></div>-->
  <ValidationObserver ref="form">
    <BaseModal size="xl" title="Chi tiết điều chỉnh thông tin đề án hoạt động XTĐT Quốc gia" :show="isDeAnModalOpen"
               @update:show="value => toggleModal({show:value})">
      <template #default>
        <div>
          <div class="w-100">
            <div class="pdf-wrapper">
              <div>
                <div class="">
                  <div class="pdf-page fs-16">
                    {{ historyDeAn }}
<!--                    item: {{ item }} <br> <br> <hr>-->
<!--                    it: {{ its }}-->
<!--                    -->
<!--                    <div id="pdf1" class="pdf-content">-->
<!--                      <div class="text-center fs-16">-->
<!--                        <strong class="bold">Mẫu C.I.3</strong><br>-->
<!--                        <strong>Đề án hoạt động xúc tiến đầu tư thuộc chương trình xúc tiến đầu tư quốc gia</strong><br>-->
<!--                        <p class="font-italic">(Áp dụng cho hồ sơ đề xuất thực hiện chương trình xúc tiến đầu tư quốc-->
<!--                          gia quy định tại Điểm b, Khoản 2, Điều 92 Nghị định số 31/2021/NĐ-CP)</p>-->
<!--                        <hr>-->
<!--                      </div>-->
<!--                      <div class="row fs-16">-->
<!--                        <div class="col-6">-->
<!--                          <b class="text-uppercase float-left">{{ its.donViChuTriThucHien }}</b> <br>-->
<!--                          <p class="float-left">Số: {{ item.soVanBan }}</p>-->
<!--                        </div>-->
<!--                        <div class="col-6">-->
<!--                          <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt-->
<!--                            Nam</p>-->
<!--                          <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>-->
<!--                          <p class="font-weight font-italic" style="float: right; display: flex">-->
<!--                          <span class="mt-2">{{ item.diaDiem }}, ngày {{-->
<!--                              getDay(item.ngayVanBan)-->
<!--                            }} tháng {{-->
<!--                              getMonth(item.ngayVanBan)-->
<!--                            }} năm {{ getYear(item.ngayVanBan) }}</span>-->
<!--                          </p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="text-center">-->
<!--                        <b class="text-uppercase">Đề án</b> <br>-->
<!--                        <span class="font-italic">(Tên hoạt động: {{ its.tenHoatDong }})</span> <br>-->
<!--                        <span class="font-italic">(Loại hoạt động: {{ displayLabel(optionsLoaiHoatDong, its.loaiHoatDongId) }})</span> <br>-->
<!--                        <span>Kính gửi: {{ its.donViChuTriThucHien }}</span> <br>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <p class="font-italic mb-0" style="text-indent: 2em">Căn cứ Luật Đầu tư số 61/2020/QH14 ngày 17-->
<!--                          tháng-->
<!--                          6 năm 2020;</p>-->
<!--                        <p class="font-italic mb-0" style="text-indent: 2em">Căn cứ Nghị định số 31/2021/NĐ-CP ngày 26-->
<!--                          tháng-->
<!--                          3 năm 2021 của Chính phủ quy định chi tiết và hướng dẫn thi hành một số điều của Luật đầu-->
<!--                          tư;</p>-->
<!--                        <p class="font-italic mb-0" style="text-indent: 2em">Căn cứ Thông tư số .../2021/TT-BKHĐT ngày-->
<!--                          ...-->
<!--                          tháng ... năm 2021 của Bộ Kế hoạch và Đầu tư quy định biểu mẫu thực hiện hoạt động dầu tư tại-->
<!--                          Việt Nam, đầu tư từ Việt Nam ra nước ngoài và xúc tiến đầu tư;</p>-->
<!--                        <p class="font-italic mb-0" style="text-indent: 2em">Căn cứ hướng dẫn xây dựng chương trình xúc-->
<!--                          tiến đầu tư của Bộ Kế hoạch và Đầu tư tại công văn số ... ngày ... tháng ... năm ....</p>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>1. Sự cần thiết</b>-->
<!--                        <p class="mb-0">- Phù hợp với định hướng thu hút đầu tư, đặc điểm, tiềm năng, thế mạnh của vùng,-->
<!--                          lãnh thổ;</p>-->
<!--                        <p class="mb-0">- Phù hợp với xu hướng và nhu cầu của nhóm đối tác, nhà đầu tư dự kiến xúc tiến-->
<!--                          đầu tư.</p>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>2. Nội dung hoạt động</b>-->
<!--                        <div class="position-relative table-responsive">-->
<!--                          <table class="table table-striped table-fixed table-hover table-bordered">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="text-center align-middle">STT</th>-->
<!--                              <th class="text-center align-middle" colspan="2">Các thông tin cần thiết</th>-->
<!--                              <th class="text-center align-middle">Cập nhật thông tin đề án</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>1</td>-->
<!--                              <td colspan="2">Thời gian thực hiện</td>-->
<!--                              <td>-->
<!--                                <v-date-picker class="" v-model="data.thoiGianThucHien" locale="vi">-->
<!--                                  <template v-slot="{ inputValue, inputEvents }">-->
<!--                                    <input-->
<!--                                      class="form-control"-->
<!--                                      :value="inputValue"-->
<!--                                      v-on="inputEvents"-->
<!--                                    />-->
<!--                                  </template>-->
<!--                                </v-date-picker>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td rowspan="2">2</td>-->
<!--                              <td rowspan="2">Địa điểm thực hiện</td>-->
<!--                              <td>Trong nước</td>-->
<!--                              <td>{{ displayLabel(optionsTinhThanh, its.diaDiem_TrongNuocId) }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>Ngoài nước</td>-->
<!--                              <td>{{ displayLabel(optionsQuocGia, its.diaDiem_NuocNgoaiId) }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>3</td>-->
<!--                              <td colspan="2">Đối tượng dự kiến tham gia</td>-->
<!--                              <td>-->
<!--                                <ValidationProvider name="Đối tượng dự kiến tham gia" rules="required"-->
<!--                                                    v-slot="{ errors, valid , touched }">-->
<!--                                  <div role="group" class="form-group form-row">-->
<!--                                    <div class="col-12">-->
<!--                                      <input id="doiTuongDuKienThamGia" type="text" class="form-control"-->
<!--                                             placeholder="Nhập đối tượng dự kiến tham gia"-->
<!--                                             :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                             v-model="data.doiTuongDuKienThamGia"/>-->
<!--                                      <div class="invalid-feedback" v-if="!valid">-->
<!--                                        <ul>-->
<!--                                          <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                        </ul>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </ValidationProvider>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>4</td>-->
<!--                              <td colspan="2">Quy mô hoạt động</td>-->
<!--                              <td>-->
<!--                                <ValidationProvider name="Quy mô hoạt động" rules="required"-->
<!--                                                    v-slot="{ errors, valid , touched }">-->
<!--                                  <div role="group" class="form-group form-row">-->
<!--                                    <div class="col-12">-->
<!--                                      <input id="quyMoHoatDong" type="text" class="form-control"-->
<!--                                             placeholder="Nhập quy mô hoạt động"-->
<!--                                             :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                             v-model="data.quyMoHoatDong"/>-->
<!--                                      <div class="invalid-feedback" v-if="!valid">-->
<!--                                        <ul>-->
<!--                                          <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                        </ul>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </ValidationProvider>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>5</td>-->
<!--                              <td colspan="2">Ngành/Lĩnh vực kêu gọi đầu tư (ngành ISIC cấp 2)</td>-->
<!--                              <td>{{ displayLabel(optionsNganhLinhVuc, its.nganhLinhVucKeuGoiDauTuId) }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>6</td>-->
<!--                              <td colspan="2">Địa bàn/Tỉnh/Vùng kêu gọi đầu tư</td>-->
<!--                              <td>{{ displayLabel(optionsTinhThanh, its.diaBanKeuGoiDauTu) }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>7</td>-->
<!--                              <td colspan="2">Các nội dung chi tiết khác</td>-->
<!--                              <td>-->
<!--                                <ValidationProvider name="Nội dung chi tiết khác" rules="required"-->
<!--                                                    v-slot="{ errors, valid , touched }">-->
<!--                                  <div role="group" class="form-group form-row">-->
<!--                                    <div class="col-12">-->
<!--                                      <input id="noiDungChiTietKhac" type="text" class="form-control"-->
<!--                                             placeholder="Nhập nội dung chi tiết khác"-->
<!--                                             :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                             v-model="data.noiDungChiTietKhac"/>-->
<!--                                      <div class="invalid-feedback" v-if="!valid">-->
<!--                                        <ul>-->
<!--                                          <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                        </ul>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </ValidationProvider>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>3. Phương thức triển khai</b>-->
<!--                        <div class="position-relative table-responsive">-->
<!--                          <table class="table table-striped table-fixed table-hover table-bordered">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="text-center align-middle">STT</th>-->
<!--                              <th class="text-center align-middle" colspan="2">Các thông tin cần cập nhật</th>-->
<!--                              <th class="text-center align-middle">Cập nhật thông tin đề án</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>1</td>-->
<!--                              <td colspan="2">Phương thức tổ chức</td>-->
<!--                              <td>-->
<!--                                <ValidationProvider name="Phương thức tổ chức" rules="required"-->
<!--                                                    v-slot="{ errors, valid , touched }">-->
<!--                                  <div role="group" class="form-group form-row">-->
<!--                                    <div class="col-12">-->
<!--                                      <input id="phuongThucToChuc" type="text" class="form-control"-->
<!--                                             placeholder="Nhập phương thức tổ chức"-->
<!--                                             :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                             v-model="data.phuongThucToChuc"/>-->
<!--                                      <div class="invalid-feedback" v-if="!valid">-->
<!--                                        <ul>-->
<!--                                          <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                        </ul>-->
<!--                                      </div>-->
<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </ValidationProvider>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>2</td>-->
<!--                              <td rowspan="4">Đơn vị phối hợp</td>-->
<!--                              <td>Tổ chức cơ quan trong nước</td>-->
<!--                              <td>{{ its.toChucCoQuan_TrongNuoc }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>3</td>-->
<!--                              <td>Tổ chức cơ quan nước ngoài</td>-->
<!--                              <td>{{ its.toChucCoQuan_NuocNgoai }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>3</td>-->
<!--                              <td>Doanh nghiệp trong nước</td>-->
<!--                              <td>{{ its.doanhNghiep_TrongNuoc }}</td>-->
<!--                            </tr>-->
<!--                            <tr class="text-center align-middle">-->
<!--                              <td>4</td>-->
<!--                              <td>Doanh nghiệp nước ngoài</td>-->
<!--                              <td>{{ its.doanhNghiep_NuocNgoai }}</td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>4. Kế hoạch triển khai và tiến độ thực hiện</b>-->
<!--&lt;!&ndash;                        <button class="btn btn-primary float-right" @click="addHangMucCongViec"><i class="cil-plus"/>Thêm&ndash;&gt;-->
<!--&lt;!&ndash;                          hạng mục&ndash;&gt;-->
<!--&lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                        <div class="position-relative table-responsive">-->
<!--                          <table class="table table-striped table-fixed table-hover table-bordered">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="text-center align-middle">STT</th>-->
<!--                              <th class="text-center align-middle">Hạng mục công việc</th>-->
<!--                              <th class="text-center align-middle">Thời gian bắt đầu</th>-->
<!--                              <th class="text-center align-middle">Thời gian kết thúc</th>-->
<!--                              <th class="text-center align-middle">Đơn vị thực hiện</th>-->
<!--                              <th class="text-center align-middle">Đơn vị phối hợp</th>-->
<!--&lt;!&ndash;                              <th class="text-center align-middle">Thao tác</th>&ndash;&gt;-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <template v-if="hangMucCongViecCTXTDTQuocGia.length">-->
<!--                              <tr class="text-center align-middle" v-for="(itz) in hangMucCongViecCTXTDTQuocGia"-->
<!--                                  v-bind:key="itz.guid">-->
<!--                                <td>{{ itz.stt }}</td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="tel" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.tenHangMuc"/>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <v-date-picker class="" v-model="itz.thoiGianBatDau" locale="vi">-->
<!--                                    <template v-slot="{ inputValue, inputEvents }">-->
<!--                                      <input-->
<!--                                        disabled style="background-color: white"-->
<!--                                        class="form-control"-->
<!--                                        :value="inputValue"-->
<!--                                        v-on="inputEvents"-->
<!--                                      />-->
<!--                                    </template>-->
<!--                                  </v-date-picker>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <v-date-picker class="" v-model="itz.thoiGianKetThuc" locale="vi">-->
<!--                                    <template v-slot="{ inputValue, inputEvents }">-->
<!--                                      <input-->
<!--                                        disabled style="background-color: white"-->
<!--                                        class="form-control"-->
<!--                                        :value="inputValue"-->
<!--                                        v-on="inputEvents"-->
<!--                                      />-->
<!--                                    </template>-->
<!--                                  </v-date-picker>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="tel" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.donViThucHien"/>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="tel" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.donViPhoiHop"/>-->
<!--                                </td>-->
<!--&lt;!&ndash;                                <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                  <button class="btn btn-ghost-primary" v-if="!itz.parentGuid"&ndash;&gt;-->
<!--&lt;!&ndash;                                          @click="addHangMucCongViecCon(itz.guid, idxz)">Thêm hạng mục con&ndash;&gt;-->
<!--&lt;!&ndash;                                  </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                  <button class="btn btn-danger" @click="deleteHangMucCongViec(idxz)"><i&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="cil-trash"/>Xóa&ndash;&gt;-->
<!--&lt;!&ndash;                                  </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                </td>&ndash;&gt;-->
<!--                              </tr>-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                              <tr class="text-center align-middle">-->
<!--                                <td colspan="7">Chưa có dữ liệu</td>-->
<!--                              </tr>-->
<!--                            </template>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>5. Kinh phí</b>-->
<!--                        <div class="position-relative table-responsive">-->
<!--                          <table class="table table-striped table-fixed table-hover table-bordered">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="text-center align-middle">STT</th>-->
<!--                              <th class="text-center align-middle">Các thông tin cần cập nhật</th>-->
<!--                              <th class="text-center align-middle">Cập nhật thông tin đề án</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr class="align-middle">-->
<!--                              <td class="text-center">1</td>-->
<!--                              <td class="text-center">Chương trình xúc tiến đầu tư quốc gia</td>-->
<!--                              <td><span class="float-right">{{ its.kinhPhi_ChuongTrinhXTDTQG | formatNumber }}</span>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            <tr class="align-middle">-->
<!--                              <td class="text-center">2</td>-->
<!--                              <td class="text-center">Ngân sách của Bộ, địa phương</td>-->
<!--                              <td><span class="float-right">{{ its.kinhPhi_NganSachDiaPhuong | formatNumber }}</span>-->
<!--                              </td>-->
<!--                            </tr>-->
<!--                            <tr class="align-middle">-->
<!--                              <td class="text-center">3</td>-->
<!--                              <td class="text-center">Khác (xã hội hóa)</td>-->
<!--                              <td><span class="float-right">{{ its.kinhPhi_Khac | formatNumber }}</span></td>-->
<!--                            </tr>-->
<!--                            <tr class="align-middle">-->
<!--                              <td class="text-center">4</td>-->
<!--                              <td class="text-center">Tổng kinh phí</td>-->
<!--                              <td><span class="float-right">{{-->
<!--                                  Number(its.kinhPhi_ChuongTrinhXTDTQG) + Number(its.kinhPhi_NganSachDiaPhuong) + Number(its.kinhPhi_Khac) | formatNumber-->
<!--                                }}</span></td>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>6. Dự toán kinh phí từ chương trình XTĐT Quốc gia (Theo mẫu C.I.4 tại điểm b, khoản 2, Điều-->
<!--                          92 Nghị định số 31/2021/NĐ-CP)</b>-->
<!--&lt;!&ndash;                        <button class="btn btn-primary float-right" @click="addDuToanKinhPhi"><i class="cil-plus"/>Thêm&ndash;&gt;-->
<!--&lt;!&ndash;                          hạng mục&ndash;&gt;-->
<!--&lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                        <div class="position-relative table-responsive">-->
<!--                          <table class="table table-striped table-fixed table-hover table-bordered">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                              <th class="text-center align-middle">STT</th>-->
<!--                              <th class="text-center align-middle">Nội dung công việc</th>-->
<!--                              <th class="text-center align-middle">Đơn vị tính</th>-->
<!--                              <th class="text-center align-middle">Số lượng</th>-->
<!--                              <th class="text-center align-middle">Đơn giá</th>-->
<!--                              <th class="text-center align-middle">Thành tiền</th>-->
<!--&lt;!&ndash;                              <th class="text-center align-middle">Thao tác</th>&ndash;&gt;-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <template v-if="duToanKinhPhiXTDTQuocGia.length">-->
<!--                              <tr class="text-center align-middle" v-for="(itz) in duToanKinhPhiXTDTQuocGia"-->
<!--                                  v-bind:key="itz.guid">-->
<!--                                <td>-->
<!--                                  &lt;!&ndash;                                  <span v-if="!itz.parentGuid">{{ idxz + 1 }}</span>&ndash;&gt;-->
<!--                                  <span>{{ itz.stt }}</span>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="text" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.noiDungCongViec"/>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="text" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.donViTinh"/>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="number" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.soLuong"/>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <input disabled style="background-color: white" type="number" class="form-control"-->
<!--                                         placeholder=""-->
<!--                                         v-model="itz.donGia"/>-->
<!--                                </td>-->
<!--                                <td>-->
<!--                                  <span-->
<!--                                    class="float-right">{{-->
<!--                                      Number(itz.soLuong) * Number(itz.donGia) | formatNumber-->
<!--                                    }}</span>-->
<!--                                </td>-->
<!--&lt;!&ndash;                                <td>&ndash;&gt;-->
<!--&lt;!&ndash;                                  <button class="btn btn-ghost-primary" v-if="!itz.parentGuid"&ndash;&gt;-->
<!--&lt;!&ndash;                                          @click="addDuToanKinhPhiCon(itz.guid, idxz)">Thêm hạng mục con&ndash;&gt;-->
<!--&lt;!&ndash;                                  </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                  <button class="btn btn-danger" @click="deleteDuToanKinhPhi(idxz)"><i&ndash;&gt;-->
<!--&lt;!&ndash;                                    class="cil-trash"/>Xóa&ndash;&gt;-->
<!--&lt;!&ndash;                                  </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                </td>&ndash;&gt;-->
<!--                              </tr>-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                              <tr class="text-center align-middle">-->
<!--                                <td colspan="7">Chưa có dữ liệu</td>-->
<!--                              </tr>-->
<!--                            </template>-->
<!--                            </tbody>-->
<!--                          </table>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                        <b>7. Đánh giá đóng góp của hoạt động xúc tiến đầu tư</b> <br>-->
<!--                        <span class="font-italic">(Dự kiến những đóng góp của hoạt động xúc tiến đầu tư đối với phát triển kinh tế - xã hội của Bộ, ngành, địa phương, vùng)</span>-->
<!--                        <ValidationProvider name="Đánh giá đóng góp" rules="required"-->
<!--                                            v-slot="{ errors, valid , touched }">-->
<!--                          <div role="group" class="form-group form-row">-->
<!--                            <div class="col-12">-->
<!--                              <textarea id="danhGiaDongGop" type="text" class="form-control"-->
<!--                                     placeholder="Nhập đánh giá đóng góp"-->
<!--                                     :class="{ 'is-valid': valid === true, 'is-invalid': touched && valid === false }"-->
<!--                                     v-model="data.danhGiaDongGop"/>-->
<!--                              <div class="invalid-feedback" v-if="!valid">-->
<!--                                <ul>-->
<!--                                  <li v-for="e in errors" :key="e">{{ e }}</li>-->
<!--                                </ul>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </ValidationProvider>-->
<!--                      </div>-->
<!--                      <div class="col-12 row mt-5">-->
<!--                        <div class="col-5 float-left">-->
<!--                          <b class="font-italic">Nơi nhận:</b> <br>-->
<!--                          <span class="font-italic">- .....</span> <br>-->
<!--                        </div>-->
<!--                        <div class="col-7 text-center">-->
<!--                          <b>THỦ TRƯỞNG ĐƠN VỊ CHỦ TRÌ</b> <br>-->
<!--                          <span class="font-italic">(Ký, ghi rõ họ tên, chức danh, đóng dấu)</span>-->
<!--                          <br>-->
<!--                          <br>-->
<!--                          <br>-->
<!--                          <br>-->
<!--                          <br>-->
<!--                          <p class="font-italic">(Ghi rõ họ tên, đơn vị, ký, điện thoại, email)</p>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div class="form-group row mb-0 mt-3">-->
<!--                        <div class="col-12">-->
<!--                          <b class="mt-5">Đính kèm file</b>-->
<!--                          <div class="import-files mb-2" v-if="JSON.parse(data.dinhKem) != null">-->
<!--                            <div class="files mb-1" v-for="file in JSON.parse(data.dinhKem)" v-bind:key="file.id">-->
<!--                              <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
<!--            item: {{ item }} <br>-->
<!--            its: {{ its.deAnHoatDongXTDTQuocGia }} <br>-->
<!--            data: {{ data }}-->
          </div>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
<!--        <button type="button" class="btn btn-primary" :disabled="invalid || isSaving" @click="handleSubmit(saveItem)">-->
<!--          <span v-if="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lưu-->
<!--          lại-->
<!--        </button>-->
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
// import FileUpload from '@/components/file-upload/FileUpload'
import { v4 as uuidv4 } from 'uuid'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { enums } from '@/shared/enums'
import appConfig from '@/shared/appConfig'
import { displayLabel } from '@/shared/appConstants'

export default {
  name: 'DeXuatCTXTDTQGDeAnDetailModal',
  props: ['item', 'its'],
  components: {
    // FileUpload
  },
  data () {
    return {
      invalid: true,
      Attachments: [],
      type: 'DeXuatCTXTDTQuocGia',
      chiTietDeXuatCTXTDTQuocGia: [],
      TrangThaiDeXuatCTXTDT: [
        {
          label: 'TATCA',
          value: 0
        }
      ],
      filterDMLoaiHoatDong: {
        loaiHoatDong: '',
        trangThai: true,
        orderBy: null,
        pageNumber: 1,
        pageSize: 50
      },
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      hangMucCongViecCTXTDTQuocGia: [],
      duToanKinhPhiXTDTQuocGia: [],
      data: {
        deXuatCTXTDTQuocGiaGuid: '',
        chiTietDeXuatCTXTDTQuocGiaGuid: '',
        guid: '',
        thoiGianThucHien: new Date(),
        doiTuongDuKienThamGia: '',
        quyMoHoatDong: '',
        noiDungChiTietKhac: '',
        phuongThucToChuc: '',
        danhGiaDongGop: '',
        dinhKem: '',
        dinhKem_DuToanKinhPhi: '',
        hangMucCongViecCTXTDTQuocGia: [],
        duToanKinhPhi: []
      }
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTQG', {
      isSaving: 'isSavingDeAn',
      isDeAnModalOpen: 'isDetailDeAnModalOpen',
      // editedItem: 'editedItem',
      historyDeAn: 'historyDeAn',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTQG', {
      toggleModal: types.TOGGLE_DETAIL_DEAN_MODAL,
      createItem: types.CREATE_DEANDEXUATCTXTDTQUOCGIA
    }),
    displayLabel,
    async saveItem () {
      const validate = await this.$refs.form.validate()
      if (validate) {
        for (const i in this.data.duToanKinhPhi) {
          this.data.duToanKinhPhi[i].soLuong = Number(this.data.duToanKinhPhi[i].soLuong)
          this.data.duToanKinhPhi[i].donGia = Number(this.data.duToanKinhPhi[i].donGia)
        }
        await this.createItem(this.data)
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$emit('onSubmit', this.data)
          this.$toast.success(this.responseResult.message)
          this.toggleModal({ show: false })
        }
      }
    },
    changeFiles (data, index) {
      this.data.dinhKem = JSON.stringify(data.ListFileAttachAdd)
    },
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    addHangMucCongViec () {
      let countStt = 0
      for (const i in this.data.hangMucCongViec) {
        if (this.data.hangMucCongViec[i].parentGuid === undefined) {
          countStt++
        }
      }
      // console.log('Count STT: ', countStt)
      const item = {
        guid: uuidv4(),
        // parentGuid: parentGuid,
        stt: countStt + 1,
        tenHangMuc: '',
        thoiGianBatDau: new Date(),
        thoiGianKetThuc: new Date(),
        donViThucHien: '',
        donViPhoiHop: '',
        countChild: 0,
        index: this.data.duToanKinhPhi.length + 1
      }
      this.data.hangMucCongViec.push(item)
    },
    deleteHangMucCongViec (index) {
      if (this.data.hangMucCongViec.parentGuid) {
        for (const i in this.data.hangMucCongViec) {
          if (this.data.hangMucCongViec[i].guid === this.data.hangMucCongViec[index].parentGuid) {
            this.data.hangMucCongViec[i].countChild--
            this.data.hangMucCongViec.splice(index, 1)
            // console.log('countChild delete', this.data.duToanKinhPhi[i].countChild)
            break
          }
        }
      } else {
        this.data.hangMucCongViec.splice(index, 1)
      }
    },
    addHangMucCongViecCon (parentGuid = '') {
      for (const i in this.data.hangMucCongViec) {
        if (this.data.hangMucCongViec[i].guid === parentGuid) {
          this.data.hangMucCongViec[i].countChild++
          const item = {
            guid: uuidv4(),
            parentGuid: parentGuid,
            stt: `${this.data.hangMucCongViec[i].stt}.${this.data.hangMucCongViec[i].countChild}`,
            tenHangMuc: '',
            thoiGianBatDau: new Date(),
            thoiGianKetThuc: new Date(),
            donViThucHien: '',
            donViPhoiHop: '',
            index: this.data.duToanKinhPhi.length + 1
          }
          this.data.hangMucCongViec.push(item)
          // console.log('countChild add', this.data.duToanKinhPhi[i].countChild)
          break
        }
      }
    },
    addDuToanKinhPhi () {
      let countStt = 0
      for (const i in this.data.duToanKinhPhi) {
        if (this.data.duToanKinhPhi[i].parentGuid === undefined) {
          countStt++
        }
      }
      // console.log('Count STT: ', countStt)
      const item = {
        guid: uuidv4(),
        // parentGuid: parentGuid,
        stt: countStt + 1,
        noiDungCongViec: '',
        donViTinh: '',
        soLuong: 0,
        donGia: 0,
        countChild: 0,
        index: this.data.duToanKinhPhi.length + 1
      }
      this.data.duToanKinhPhi.push(item)
    },
    deleteDuToanKinhPhi (index) {
      if (this.data.duToanKinhPhi.parentGuid) {
        for (const i in this.data.duToanKinhPhi) {
          if (this.data.duToanKinhPhi[i].guid === this.data.duToanKinhPhi[index].parentGuid) {
            this.data.duToanKinhPhi[i].countChild--
            this.data.duToanKinhPhi.splice(index, 1)
            // console.log('countChild delete', this.data.duToanKinhPhi[i].countChild)
            break
          }
        }
      } else {
        this.data.duToanKinhPhi.splice(index, 1)
      }
    },
    addDuToanKinhPhiCon (parentGuid, index) {
      for (const i in this.data.duToanKinhPhi) {
        if (this.data.duToanKinhPhi[i].guid === parentGuid) {
          this.data.duToanKinhPhi[i].countChild++
          const item = {
            guid: uuidv4(),
            parentGuid: parentGuid,
            stt: `${this.data.duToanKinhPhi[i].stt}.${this.data.duToanKinhPhi[i].countChild}`,
            noiDungCongViec: '',
            donViTinh: '',
            soLuong: 0,
            donGia: 0,
            index: this.data.duToanKinhPhi.length + 1
          }
          this.data.duToanKinhPhi.push(item)
          // console.log('countChild add', this.data.duToanKinhPhi[i].countChild)
          break
        }
      }
    },
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
  },
  watch: {
    its: function (val) {
      // this.data.guid = uuidv4()
      // this.data.chiTietDeXuatCTXTDTQuocGiaGuid = val.guid
      // this.data.deXuatCTXTDTQuocGiaGuid = val.deXuatCTXTDTQuocGiaGuid
      console.log('Đề án detail:', val)
      // this.data = val.deAnHoatDongXTDTQuocGia
      // this.duToanKinhPhiXTDTQuocGia = val.deAnHoatDongXTDTQuocGia.duToanKinhPhiXTDTQuocGia
      // this.hangMucCongViecCTXTDTQuocGia = val.deAnHoatDongXTDTQuocGia.hangMucCongViecCTXTDTQuocGia
      // this.data.duToanKinhPhi = val.duToanKinhPhiXTDTQuocGia
      // this.data.hangMucCongViecCTXTDTQuocGia = val.hangMucCongViecCTXTDTQuocGia
    }
    // item: function (val) {
    //   this.data.deXuatCTXTDTQuocGiaGuid = val.guid
    // }
  }
}
</script>
