<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Lịch sử chương trình Xúc tiến đầu tư Quốc gia
        </CCardHeader>
        <CCardBody>
          <div class="position-relative table-responsive">
            <table class="table table-striped table-fixed table-hover table-bordered">
              <thead>
              <tr class="align-middle">
                <!--                <th class="position-relative pr-4">STT</th>-->
                <th class="position-relative pr-4">Phiên bản</th>
                <th class="position-relative pr-4">Số văn bản</th>
                <!--                <th class="position-relative pr-4">Ngày cập nhật gần nhất</th>-->
                <th class="position-relative pr-4">Ngày văn bản</th>
                <th class="position-relative pr-4">Đánh giá</th>
                <th class="position-relative pr-4">Nội dung</th>
                <th class="position-relative pr-4">Mục tiêu</th>
                <th class="position-relative pr-4">Đính kèm</th>
                <!--                <th class="position-relative pr-4">Ngày chỉnh sửa</th>-->
                <!--                <th class="position-relative pr-4">Chi tiết phụ lục</th>-->
              </tr>
              </thead>
              <tbody class="position-relative">
              <template v-if="historyData">
                <tr v-for="(item) in historyData" :key="item.id">
                  <!--                  <td>{{ index + 1 }}</td>-->
                  <td>{{ item.version }}</td>
                  <td>{{ item.soVanBan }}</td>
                  <!--                  <td>{{ formatDateData(item.modifiedDate) }}</td>-->
                  <td>{{ formatDateData(item.ngayVanBan) }}</td>
                  <td>{{ item.danhGiaHoatDongNamTruoc }}</td>
                  <td>{{ item.noiDung }}</td>
                  <td>{{ item.mucTieu }}</td>
                  <td>
                    <div v-if="item.dinhKem">
                      <div class="import-files mb-2" v-if="JSON.parse(item.dinhKem).length">
                        <div class="files mb-1" v-for="file in JSON.parse(item.dinhKem)" v-bind:key="file.id">
                          <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!--                  <td>{{ formatDateData(item.modifiedDate) }}</td>-->
                  <!--                  <td :rowspan="historyData.length" class="align-content-center text-center">-->
                  <!--                    <CButton size="sm" color="info" class="mr-2" @click="toggleDetailPhuLucModal({show:true})"-->
                  <!--                             title="Chi tiết điều chỉnh">-->
                  <!--                      <CIcon name="cil-options"/>-->
                  <!--                    </CButton>-->
                  <!--                  </td>-->
                </tr>
                <tr>
                  <td colspan="10" class="text-center">
                    <CButton size="sm" color="primary" class="mr-2" @click="toggleDetailPhuLucModal({show:true})"
                             title="Chi tiết điều chỉnh">
                      <CIcon name="cil-options"/> Chi tiết lịch sử phụ lục
                    </CButton>
                  </td>
                </tr>
              </template>
              <tr v-if="historyData.length === 0">
                <td colspan="10">
                  <slot name="no-items-view">
                    <div class="text-center my-5">
                      <h2>
                        Không có dữ liệu
                        <CIcon width="30" name="cil-ban" class="text-danger mb-2"/>
                      </h2>
                    </div>
                  </slot>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <DeXuatCTXTDTQGPhuLucDetailModal2/>
  </CRow>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { displayLabel } from '@/shared/appConstants'
import DeXuatCTXTDTQGPhuLucDetailModal2 from '@/components/DeXuatCTXTDTQG/DeXuatCTXTDTQGPhuLucDetailModal2'
import { TOGGLE_DETAIL_MODAL } from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import appConfig from '@/shared/appConfig'

export default {
  name: 'DeXuatCTXTDTQGHistory',
  components: {
    DeXuatCTXTDTQGPhuLucDetailModal2
  },
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDTQuocGia',
      isLoading: false,
      itemId: -1,
      idprop: -1
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTQG', {
      // isPhuLucModalOpen: 'isHistoryModalOpen',
      historyData: 'historyData',
      historyDetailData: 'historyDetail',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTQG', {
      getListHistory: types.GET_HISTORY,
      getHistoryDetail: types.GET_HISTORYDETAIL,
      toggleDetailPhuLucModal: TOGGLE_DETAIL_MODAL
    }),
    displayLabel,
    async fetchListItem (id) {
      await this.getListHistory(id)
      // for (const i in this.historyData) {
      //   if (this.historyData[i].dinhKem) {
      //     this.historyData[i].Attachments = JSON.parse(this.historyData[i].dinhKem)
      //     // this.Attachments.push(this.historyData[i].Attachments)
      //   }
      //   this.historyData[i].show = false
      // }
      await this.getHistoryDetail(id)
    },
    viewItem (item) {
      // this.idprop = item.id
      // this.toggleHistoryDetailModal({ show: true })
      // window.open(`/de-xuat-ct-xtdt/${item.id}/chi-tiet-dieu-chinh`, '_blank')
      this.$router.push({ path: `${item.id}/chi-tiet-dieu-chinh` })
    },
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    }
  },
  async mounted () {
    await this.fetchListItem(this.$route.params.id)
  },
  watch: {
    historyData: function (val) {
      for (const i in val) {
        if (val[i].dinhKem) {
          val[i].Attachments = JSON.parse(val[i].dinhKem)
          // this.Attachments.push(this.historyData[i].Attachments)
          val[i].show = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
